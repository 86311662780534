<template>
  <div>
    <PageIntroduce
      :title="'渠道管理'"
      :desc="'渠道是指企业收集用户的同意信息的方式，支持网站、API、微信小程序等渠道类型，通过设置渠道和关联信息，将用户的同意信息进行汇总存储。'"
    />
    <b-card>
      <div class="filter-wrap flex-between">
        <SearchFilter
          :search-str.sync="listQuery.SearchStr"
          @handleFilter="handleFilter"
        />
        <el-button
          v-if="savePerm"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="saveDialogShow(1)"
        >
          新增
        </el-button>
      </div>
      <FilterSelectList
        :list-query.sync="listQuery"
        :select-list.sync="selectList"
        @handleFilter="handleFilter"
      />
      <el-table
        v-loading="loading"
        border
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <!--        <el-table-column-->
        <!--                label="渠道ID"-->
        <!--                prop="productManagerId"-->
        <!--        />-->
        <el-table-column
          prop="channelName"
          :show-overflow-tooltip="true"
          label="渠道名称"
        />
        <el-table-column
          prop="channelType"
          :show-overflow-tooltip="true"
          label="渠道类型"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <TableHeaderFilterDropdown
              :key-str="'channelType'"
              :select-list.sync="selectList"
              :label="'渠道类型'"
              :value.sync="listQuery.channelType"
              :dropdown-options="channelTypeOption"
              @handleFilter="handleFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="发布时间"
        >
          <template slot-scope="scope">
            {{ formatDateStr(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="UserCount"
          label="采集数量"
        />
        <el-table-column
          prop="status"
          label="状态"
        >
          <template slot-scope="{row}">
            <el-switch
              :value="row.status"
              :active-value="1"
              :inactive-value="0"
              active-color="rgb(19, 206, 102)"
              inactive-color="rgb(255, 73, 73)"
              @change="updateStatus(row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          :show-overflow-tooltip="true"
          label="备注"
        />
        <el-table-column
          label="操作"
          width="100px"
        >
          <template slot-scope="{row}">
            <el-link
              v-if="updatePerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="saveDialogShow(2,row.id)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="delPerm"
              :underline="false"
              type="primary"
              @click="del(row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-row
        type="flex"
        justify="end"
      >
        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.pageSize"
          @pagination="getList"
        />
      </el-row>
    </b-card>
    <CPMEdit
      ref="cpmedit"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import pagination from '@core/components/pagination/Pagination.vue'
import CPMEdit from '@/views/cpm/CPMEdit.vue'
import { PageChannel, DelChannel, UpdateChannelStatus } from '@/api/cpm/cpm'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'

export default {
  name: 'CPMList',
  components: {
    pagination,
    CPMEdit,
    PageIntroduce,
    FilterSelectList,
    TableHeaderFilterDropdown,
  },
  data() {
    return {
      savePerm: findBtnPerm('cpm-channel-2'),
      updatePerm: findBtnPerm('cpm-channel-3'),
      delPerm: findBtnPerm('cpm-channel-4'),
      loading: false,
      total: 0,
      selectList: [],
      channelTypeOption: [{
        label: '网站',
        value: '网站',
      },
      {
        label: '微信小程序',
        value: '微信小程序',
      },
      {
        label: 'APP',
        value: 'APP',
      },
      {
        label: '其他',
        value: '其他',
      }],
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
        channelType: '',
      },
      tableData: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'cpm-channel-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'cpm-channel-3')
      this.delPerm = verifyBtnPerm(btnPerms, 'cpm-channel-4')
    })
    this.getList()
  },
  methods: {
    updateStatus(row) {
      const msgStr = row.status === 2 ? '确定启用吗?' : '确定停用吗'
      const status = row.status === 2 ? 1 : 2
      this.confirm(msgStr, () => {
        const submitData = {
          id: row.id,
          status,
        }
        UpdateChannelStatus(submitData).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.dialogVisible = false
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    del(id) {
      this.confirm('确认删除吗?', () => {
        DelChannel({ id })
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.handleFilter()
            } else {
              error(resData.msg)
            }
          })
      })
    },
    saveDialogShow(type, id) {
      this.$refs.cpmedit.dialogVisible = true
      if (type === 2) {
        this.$refs.cpmedit.form.id = id
      }
    },
    getList() {
      this.loading = true
      PageChannel(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
