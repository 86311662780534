<template>
  <el-dialog
      :title=" form.id ? '编辑' : '新增'"
      :visible.sync="dialogVisible"
      width="620px"
      @close="dialogClose"
      @open="dialogOpen"
  >
    <el-form
        v-if="dialogVisible"
        ref="form"
        v-loading="loading"
        :model="form"

        label-width="120px"
    >
      <el-form-item
          label="渠道名称"
          prop="channelName"
          maxlength="32"
          :rules="[ { required: true, message: '渠道名称不能为空', trigger: 'change' }]"
      >
        <el-input
            v-input-filter
            v-model="form.channelName"
            maxlength="80"
            placeholder="请输入渠道名称"
        />
      </el-form-item>
      <el-form-item
          label="域名"
          v-input-filter
          prop="DomainName"
          :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
      >
        <el-input
            v-model="form.DomainName"
            maxlength="100"
        />
      </el-form-item>
      <el-form-item
          label="渠道类型"
          prop="channelType"
          :rules="[ { required: true, message: '监测方不能为空', trigger: 'change' }]"
      >
        <el-select v-model="form.channelType">
          <el-option v-for="(item,index) in ['网站','微信小程序','APP','其他']" :key="index" :label="item"
                     :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          label="渠道状态"
      >
        <el-switch
            :active-value="1"
            :inactive-value="0"
            active-color="rgb(19, 206, 102)"
            inactive-color="rgb(255, 73, 73)"
            v-model="form.status">
        </el-switch>
      </el-form-item>
      <el-form-item
          label="隐私政策"
          prop="privacyPolicyUrl"
          :rules="[ { required: true, message: '隐私政策不能为空', trigger: 'change' }]"
      >
        <el-input
            v-input-filter
            v-model="form.privacyPolicyUrl"
            maxlength="100"
        />
      </el-form-item>
      <el-form-item
          label="备注"
      >
        <el-input
            maxlength="255"
            v-input-filter
            type="textarea"
            v-model="form.remark"
            placeholder="请输入描述"
        />
      </el-form-item>
    </el-form>
    <div
        slot="footer"
        class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
          type="primary"
          :disabled="loading"
          @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saveOrUpdateChannel, GetChannelInfo } from '@/api/cpm/cpm'
import { success, error } from '@core/utils/utils'

export default {
  data() {
    return {
      users: [],
      loading: false,
      dialogVisible: false,
      form: {
        DomainName: '',
        channelName: '',
        channelType: '',
        privacyPolicyUrl: '',
        remark: '',
        status: 1,
        id: '',
      },
    }
  },
  methods: {
    dialogOpen() {
      if (this.form.id) {
        this.getChannelInfo()
      }
    },
    dialogClose() {
      this.form = {
        DomainName: '',
        channelName: '',
        channelType: '',
        privacyPolicyUrl: '',
        remark: '',
        status: 1,
        id: '',
      }
      this.$refs.form.resetFields()
    },
    getChannelInfo() {
      GetChannelInfo(this.form.id)
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              this.form = resData.data
            }
          })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          if (!this.form.id) {
            delete this.form.id
          }
          saveOrUpdateChannel(this.form)
              .then(res => {
                this.loading = false
                const resData = res.data
                if (resData.code === 0) {
                  success(resData.msg)
                  this.dialogVisible = false
                  this.$emit('handleFilter')
                } else {
                  error(resData.msg)
                }
              })
        } else {
          return false
        }
      })
    },
  },
}
</script>
